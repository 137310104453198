import { type AxiosInstance } from 'axios'

import { type Configuration } from '@redteclab/api/clients/bully'

import { useGlobalConfigContext } from '../../../global-config'

import { buildClientSideBullyAxiosInstance } from './buildClientSideBullyAxiosInstance'

let bullyClientCache: Map<unknown, unknown> | null = null

const getBullyClientCache = (): Map<unknown, unknown> => {
  bullyClientCache ??= new Map()

  return bullyClientCache
}

/**
 * CLIENTSIDE HOOK
 *
 * This function retrieves bully api clients generated from API Contracts (@redteclab/api)
 * and caches them, so the same instance is reused.
 *
 * @example
 * ```
 * const apiClient = useBullyApiClient(SomeGeneratedControllerFactory)
 * apiClient.someMethod(...)
 * ```
 */
export const useBullyApi = <
  T extends (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
  ) => ReturnType<T>,
>(
  apiClientFactory: T,
): ReturnType<T> => {
  const publicGlobalConfig = useGlobalConfigContext()

  const apiClientCache = getBullyClientCache()

  const inCache = apiClientCache.get(apiClientFactory) as
    | ReturnType<T>
    | undefined

  if (inCache) {
    return inCache
  }

  const newInstance = apiClientFactory(
    undefined,
    publicGlobalConfig.bullyUrl,
    buildClientSideBullyAxiosInstance(publicGlobalConfig),
  )

  apiClientCache.set(apiClientFactory, newInstance)

  return newInstance
}
