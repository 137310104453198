'use client'

import {
  createContext,
  type FC,
  type PropsWithChildren,
  useContext,
} from 'react'

import { type PublicGlobalConfig } from '../config-types/PublicGlobalConfig'

const GlobalConfigContext = createContext<PublicGlobalConfig | null>(null)

/**
 * Global config context provider, app supposed to have only one such context.
 */
export const GlobalConfigContextProvider: FC<
  PropsWithChildren<{
    publicGlobalConfig: PublicGlobalConfig
  }>
> = ({ children, publicGlobalConfig }) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- do extra check as we could miss config in getServerSideProps or getStaticProps
  if (!publicGlobalConfig) {
    /*
     * commenting until we will have solution for how to handle static error page generation
     * throw new Error('NO_GLOBAL_CONFIG_PROVIDED_TO_CONTEXT')
     */
  }

  return (
    <GlobalConfigContext.Provider value={publicGlobalConfig}>
      {children}
    </GlobalConfigContext.Provider>
  )
}

/**
 * For using the context value in consumer components.
 */
export const useGlobalConfigContext = (): PublicGlobalConfig => {
  const globalConfig = useContext(GlobalConfigContext)

  if (globalConfig === null) {
    throw new Error('NO_CONTEXT_VALUE_PROVIDED')
  }

  return globalConfig
}
