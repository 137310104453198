import axios, { type AxiosInstance } from 'axios'

import { type PublicGlobalConfig } from '../../../global-config'
import {
  HTTP_HEADER_ACCEPT_LANGUAGE,
  HTTP_HEADER_CACHE_CONTROL,
} from '../../../http'

let clientSideBullyAxiosInstance: AxiosInstance | null = null

/**
 * Build axios instance for bully api clients, with default configs.
 * Instance supposed to be reused so do not put any "per request" logic in here.
 */
export const buildClientSideBullyAxiosInstance = (
  publicGlobalConfig: PublicGlobalConfig,
): AxiosInstance => {
  clientSideBullyAxiosInstance ??= axios.create({
    headers: {
      /**
       * Cache `axios` instance with current locale as it's not changing during user session.
       * Links to other languages are hard, no client navigation happens between site languages.
       */
      [HTTP_HEADER_ACCEPT_LANGUAGE]: publicGlobalConfig.locale,
      [HTTP_HEADER_CACHE_CONTROL]: 'no-cache',
    },
  })

  return clientSideBullyAxiosInstance
}
