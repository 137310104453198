'use client'

import { mutate, type SWRResponse } from 'swr'
import useImmutableSwr from 'swr/immutable'

import {
  ClientSessionControllerV1ApiFactory,
  type ClientSessionCustomerData,
} from '@redteclab/api/clients/bully'

import { useBullyApi } from '../../../base-api-communication'
import { useGlobalConfigContext } from '../../../global-config'

const getUserApiSwrSessionV1Customer = (): string =>
  `useUserApiSwrFetchUserSessionData`

/**
 * Fetches user session data
 * if logged in returns user data
 * if not logged in does not throw but returns object with empty values
 * we can understand that user is logged in if response includes non empty customerNumber.
 */
export const useUserApiSwrSessionV1Customer = ({
  onSuccess,
}: {
  onSuccess?: (data: ClientSessionCustomerData) => void
} = {}): SWRResponse<ClientSessionCustomerData> => {
  const api = useBullyApi(ClientSessionControllerV1ApiFactory)
  const globalConfig = useGlobalConfigContext()

  const fetcher = async (): Promise<ClientSessionCustomerData> => {
    const response = await api.sessionV1TenantCustomerGet({
      tenant: globalConfig.tenantAndEnv,
    })

    return response.data
  }

  return useImmutableSwr(getUserApiSwrSessionV1Customer(), fetcher, {
    onSuccess: (data: ClientSessionCustomerData) => {
      onSuccess?.(data)
    },
  })
}

/**
 * triggers refetching of any active swr for the query
 */
export const refetchUserApiSwrSessionV1Customer = async (): Promise<
  ClientSessionCustomerData | undefined
> => {
  return mutate<ClientSessionCustomerData>(getUserApiSwrSessionV1Customer())
}
